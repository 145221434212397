@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Display:wght@100..900&family=Playwrite+IE+Guides&family=Space+Grotesk:wght@300..700&display=swap');
/* 1. Use a more-intuitive box-sizing model */
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* 2. Remove default margin */
* {
  margin: 0;
}

body {

  /* 3. Add accessible line-height */
  line-height: 1.5;
  /* 4. Improve text rendering */
  -webkit-font-smoothing: antialiased;
}

/* 5. Improve media defaults */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/* 6. Inherit fonts for form controls */
input,
button,
textarea,
select {
  font: inherit;
}

/* 7. Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* 8. Improve line wrapping */
p {
  text-wrap: pretty;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.ant-layout {
  min-height: 100%;
}

.ant-layout-header {

  background-color: #fff;
}

:where(.css-dev-only-do-not-override-h02n2j).ant-popover .ant-popover-inner {
  padding: 0px;
}

.ant-modal-footer .ant-btn:where(.css-dev-only-do-not-override-h02n2j).ant-btn {
  padding: 15px;
}
:where(.css-dev-only-do-not-override-zgsv2e).ant-menu .ant-menu-item-group-title {
  padding: 0px;
}