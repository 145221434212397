.logo {
  width: 92px;
  height: 36px;
}

.micro-green {
  width: 71px;
  height: 71px;
  position: absolute;
  bottom: 8px;
}

.micro-green img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.affix-bottom-navbar {
  position: relative;
}

.menu-icon-bottom-left {
  margin-right: 40px;
}

.menu-icon-bottom-right {
  margin-left: 10px;
}

.ant-layout-sider-trigger {
  margin-bottom: 57px;
}

.lang-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-tree-node-content-wrapper {
  display: flex;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  /* Adjust based on your layout */
  display: inline-block;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 1px #4e4b4b;
  border-radius: 4px;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
  background: #f46b45;
  min-height: 40px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
  background: #f46b45;
}

.wrapper-flex {
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
}

.note-container {
  padding: 16px;
  background-color: #f9f9f9;
  /* Light background for contrast */
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for elevation */
}

.note-paragraph {
  margin-top: 8px;
  /* Consistent spacing between segments */
  font-size: 16px;
  /* Slightly larger font for readability */
  color: #595959;
  /* Subtle gray color for the text */
}

.icon-create {
  height: auto !important;
  /* Resets height */
  min-height: unset !important;
  line-height: normal !important;
  width: 100%;
}

.ant-menu-item {
  transition: box-shadow 0.3s ease;
  /* Smooth transition for hover effects */
}

.session-more-btn {
  padding-left: 16px;
}

/* Custom scrollbar for the menu */
.custom-menu-item::-webkit-scrollbar {
  width: 8px;
  /* Adjust scrollbar width */
  margin-left: 10px;
  /* Creates a margin on the left */
}

.custom-menu-item::-webkit-scrollbar-track {
  background: transparent;
  /* Hide the track */
}

.custom-menu-item::-webkit-scrollbar-thumb {
  background-color: #bfbebe;
  border-radius: 10px;
  /* Color of the scrollbar handle */
}



.ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}

.circle {
  z-index: 99999;
  width: 56px;
  height: 56px;
  background-color: #17519a;
  border-radius: 50%;
  /* Makes the div a circle */
  display: flex;
  align-items: center;
  /* Center the rectangle vertically */
  justify-content: center;
  /* Center the rectangle horizontally */
  position: relative;
}

.rectangle {
  position: relative;
  width: 16px;
  height: 16px;
  background-color: white;
  position: absolute;
  border-radius: 2px;
}

/* animation */
.circle-container {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 56px;
  bottom: 20px;
}

.pulse-effect {
  z-index: -111;
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #bfd0e5;
  /* Semi-transparent blue */
  animation: pulse-wave 2s infinite ease-out;
}

.gPkrjI {
  padding: 20px 10px !important;
}

@keyframes pulse-wave {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }

  100% {
    transform: scale(2);
    /* Adjust scale for desired wave size */
    opacity: 0;
  }
}

.recording-dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  animation: recording-blink 0.8s infinite alternate;
}

@keyframes recording-blink {
  0% {
    opacity: 1;
    /* transform: scale(1); */
  }

  100% {
    opacity: 0;
    /* transform: scale(1.2); */
  }
}

.ant-menu-item-group-title {
  font-weight: bold;
  color: inherit !important;
  font-size: 20px !important;
  padding-left: 10px !important;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  width: auto !important;
  /* Ensures the width is not restricted */
  max-width: none !important;
  /* Removes any max-width limitation */
}




.custom-selected {
  background-color: #204771 !important;
  /* Custom highlight color */
  color: white !important;
}


.anticon-folder {
  font-size: x-large;
}

.ant-tree-title {
  display: flex;
}

.demo-logo a {
  text-decoration: none;
  color: inherit;
}

.demo-logo a:hover {
  color: inherit;
}

/* Ensure modal background changes */
.check-modal .ant-modal-content {
  background: #204771 !important;
  border-radius: 12px;
  padding: 20px;
  height: 300px;
  text-align: center;
}

/* Ensure text inside modal is visible */
.check-modal .ant-modal-content p,
.check-modal .ant-modal-content .ant-typography {
  color: #ffffff !important;
}

/* Wave Animation Container */
.wave-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  gap: 8px;
}

/* button create */
.button-create {
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  text-decoration: none;
  background: #e9edf1;
  cursor: pointer;
  transition: color 0.5s ease-out;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  border: none;
}

.button-create::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #204771;
  border-radius: 50%;
  bottom: 0px;
  right: 0px;
  transform: scale(0);
  transition: transform 0.5s ease-out;
  z-index: 0;
}

.button-create:hover span {
  color: white;
}

.button-create:hover {
  background: #e9edf1 !important;
  border: none;
}

.button-create:hover::before {
  transform: scale(50);
}

.button-create span,
.button-create svg {
  position: relative;
  z-index: 2;
}

.icon-wrapper {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c9d3dd;
}

.button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

/* Wave Bars */
.wave {
  width: 8px;
  height: 40px;
  background: #ffffff !important;
  /* Change wave color to white */
  border-radius: 4px;
  transform-origin: bottom;
}

/* Default Light Mode */
body {
  background-color: #ffffff;
  color: #000000;
  transition: background 0.3s, color 0.3s;
}

/* Dark Mode Styles */
.dark-mode body {
  background-color: #121212;
  color: #ffffff;
}

.dark-mode .ant-layout {
  background-color: #1f1f1f !important;
}

.dark-mode .ant-card {
  background-color: #262626 !important;
  color: white;
}

.dark-mode .ant-switch-checked {
  background-color: #1890ff !important;
}

@keyframes textReveal {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.reveal {
  animation: textReveal 0.6s ease-in-out;
}

.reveal-1 {
  color: transparent;
  /* Ensure text is initially invisible */
  /* background: linear-gradient(90deg, red, blue);  */
  /* Gradient to reveal */
  background-size: 0% 100%;
  /* Start with no gradient */
  background-repeat: no-repeat;
  /* Ensure gradient doesn’t repeat */
  -webkit-background-clip: text;
  /* Clip the background to the text */
  background-clip: text;
  animation: textRevealGradient 1.2s 0.5s both ease-in-out;
  /* Smooth reveal */
}

@keyframes textRevealGradient {
  from {
    background-size: 0% 100%;
    /* No gradient */
  }

  to {
    background-size: 100% 100%;
    /* Full gradient visible */
  }
}